<template>
    <el-row class="warp">
        <el-col :span="24" class="warp-breadcrum">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/' }"><b>首页</b></el-breadcrumb-item>
                <el-breadcrumb-item>内容管理</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/otherPage/list' }">其他页面</el-breadcrumb-item>
                <el-breadcrumb-item>编辑</el-breadcrumb-item>
            </el-breadcrumb>
        </el-col>
        <el-col :span="24" class="warp-main">
            <el-form ref="form" :model="form" :rules="rules" label-width="150px" class="el-form-self">

                <el-row>
                    <el-col>
                        <el-form-item label="类型:">
                            <div class="view-text">{{form.name}}</div>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="8">
                        <el-form-item label="音频:">
                            <el-upload class="upload-demo"
                                    :action="uploadFileURL"
                                    :multiple="false"
                                    :limit="1"
                                     :on-preview="handleVideoPreview"
                                     :on-success="handleVideoSuccess"
                                     :before-upload="beforeVideoUpload"
                                     :on-remove="handleVideoRemove"
                                     :on-exceed="handleVideoExceed"
                                    :file-list="videoUrlList">
                                <el-button size="small" type="primary">点击上传</el-button>
                                <div slot="tip" class="el-upload__tip">只能上传mp3文件，且不超过20MB</div>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col>
                        <el-form-item label="内容:" prop="content" class="is-required">
                            <Editor id="tinymce" v-model="form.content" :init="editorInit"></Editor>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row class="form-buttons">
                    <el-form-item class="el-form-item-self">
                        <el-button type="primary" @click="$router.go(-1)">返回</el-button>
                        <el-button type="primary" @click="onSubmit">保存</el-button>
                        <el-button type="primary" @click="temporarySubmit">发布</el-button>
                    </el-form-item>
                </el-row>
            </el-form>
            <el-dialog :visible.sync="videoUrlDialogVisible" >
                <audio v-if="form.videoUrl" :src="downloadFileURL+form.videoUrl+'/'" controls/>
            </el-dialog>
        </el-col>
    </el-row>
</template>
<script>
    import {reqOtherPageById, reqOtherPageEdit} from '../../../api/otherPage-api'
    import {downloadFileURL, uploadFileURL} from '../../../api/system-api'
    import {SERVER_HOST} from '../../../common/portConfig'
    import tinymce from 'tinymce/tinymce'
    import 'tinymce/themes/silver/theme'
    import Editor from '@tinymce/tinymce-vue'
    import 'tinymce/plugins/code'
    import 'tinymce/plugins/print'
    import 'tinymce/plugins/preview'
    import 'tinymce/plugins/importcss'
    import 'tinymce/plugins/searchreplace'
    import 'tinymce/plugins/autolink'
    import 'tinymce/plugins/autosave'
    import 'tinymce/plugins/save'
    import 'tinymce/plugins/directionality'
    import 'tinymce/plugins/visualblocks'
    import 'tinymce/plugins/visualchars'
    import 'tinymce/plugins/fullscreen'
    import 'tinymce/plugins/image'
    import 'tinymce/plugins/media'
    import 'tinymce/plugins/link'
    import 'tinymce/plugins/codesample'
    import 'tinymce/plugins/table'
    import 'tinymce/plugins/charmap'
    import 'tinymce/plugins/hr'
    import 'tinymce/plugins/pagebreak'
    import 'tinymce/plugins/nonbreaking'
    import 'tinymce/plugins/anchor'
    import 'tinymce/plugins/toc'
    import 'tinymce/plugins/insertdatetime'
    import 'tinymce/plugins/advlist'
    import 'tinymce/plugins/lists'
    import 'tinymce/plugins/wordcount'
    import 'tinymce/plugins/imagetools'
    import 'tinymce/plugins/textpattern'
    import 'tinymce/plugins/noneditable'
    import 'tinymce/plugins/help'
    import 'tinymce/plugins/charmap'
    import 'tinymce/plugins/quickbars'
    import 'tinymce/plugins/lists'
    import 'tinymce/plugins/media'
    import 'tinymce/plugins/indent2em'
    import 'tinymce/icons/default/icons'
    import '../../../../public/static/tinymce/langs/zh_CN.js'

    export default {
        components: {
            Editor
        },
        data() {
            return {
                videoUrlDialogVisible:false,
                uploadFileURL: uploadFileURL,
                downloadFileURL: downloadFileURL,
                editPath: '/otherPage/list',
                defaultMsg: '',
                videoUrlList: [],
                form: {
                    videoUrl: '',
                    content: '',
                    status: '',
                    publisher: '',
                    systemUser: '',
                    videoName: ''
                },
                editorInit: {
                    language_url: '/static/tinymce/langs/zh_CN.js',
                    language: 'zh_CN',
                    skin_url: '/static/tinymce/skins/ui/oxide',
                    content_css: "/static/tinymce/skins/content/default/content.css",
                    height: 800,
                    font_formats: '微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;',
                    plugins: 'code indent2em print preview importcss searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image link codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars media',
                    toolbar: 'undo redo |code bold italic underline strikethrough indent2em | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak charmap | fullscreen  preview print | image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
                    file_picker_types: 'media file',
                    // images_upload_url: '/editorfile/uploadFile',
                    autosave_ask_before_unload: false,
                    file_picker_callback: function (callback, value, meta) {
                        if (meta.filetype === 'media') {
                            var input = document.createElement('input');
                            input.setAttribute('type', 'file');
                            //你可以给input加accept属性来限制上传的文件类型
                            //例如：input.setAttribute('accept', '.jpg,.png');
                            input.setAttribute('accept', '.mp4');
                            input.click();
                            input.oninput = function () {
                                var file = this.files[0];

                                var xhr, formData;
                                console.log(file.name);
                                xhr = new XMLHttpRequest();
                                xhr.open('POST', SERVER_HOST + '/editorfile/uploadVideoFile');
                                // xhr.withCredentials = true ;
                                xhr.onload = function () {
                                    var json;
                                    if (xhr.status != 200) {
                                        alert("上传视频失败");
                                        return false;
                                    }
                                    json = JSON.parse(xhr.responseText);
                                    if (!json || typeof json.location != 'string') {
                                        alert("上传视频失败");
                                        return false;
                                    }
                                    callback(json.location);
                                };
                                formData = new FormData();
                                if (file.size / 1024 / 1024 > 100) {
                                    alert("上传视频不能超过100m");
                                    return false;
                                }
                                formData.append('file', file, file.name);
                                xhr.send(formData);
                            }
                        } else if (meta.filetype === 'file') {
                            var input = document.createElement('input');
                            input.setAttribute('type', 'file');
                            //你可以给input加accept属性来限制上传的文件类型
                            //例如：input.setAttribute('accept', '.jpg,.png');
                            input.setAttribute('accept', '.xls,.xlsx,.doc,.docx,.pdf');
                            input.click();
                            input.oninput = function () {
                                var file = this.files[0];

                                var xhr, formData;
                                console.log(file.name);
                                xhr = new XMLHttpRequest();
                                xhr.open('POST', SERVER_HOST + '/editorfile/uploadFile');
                                xhr.withCredentials = true;
                                xhr.onload = function () {
                                    var json;
                                    if (xhr.status != 200) {
                                        alert('上传失败');
                                        return false;
                                    }
                                    json = JSON.parse(xhr.responseText);
                                    if (!json || typeof json.data != 'string') {
                                        alert('上传失败');
                                        return false;
                                    }
                                    callback(json.data, {text: ''});
                                };
                                formData = new FormData();
                                if (file.size / 1024 / 1024 > 20) {
                                    alert('上传失败');
                                    return false;
                                }
                                formData.append('file', file, file.name);
                                xhr.send(formData);
                            }
                        }
                    },
                    images_upload_handler: function (blobInfo, succFun, failFun) {
                        var xhr, formData;
                        var file = blobInfo.blob();//转化为易于理解的file对象
                        xhr = new XMLHttpRequest();
                        xhr.open('POST', SERVER_HOST + '/editorfile/uploadFile', true);
                        xhr.withCredentials = true;
                        xhr.onload = function () {
                            var json;
                            if (xhr.status != 200) {
                                failFun('上传图片失败');
                                return;
                            }
                            json = JSON.parse(xhr.responseText);
                            if (!json || typeof json.data != 'string') {
                                failFun('上传图片失败');
                                return;
                            }
                            succFun(json.data);
                        };
                        formData = new FormData();
                        formData.append('file', file, file.name);
                        xhr.send(formData);
                    }
                },
                rules:{
                    content:[
                        {required: true, message: '请输入内容',trigger: 'blur'},
                    ],
                }
            }
        },
        methods: {

            //-----------------音频
            beforeVideoUpload(file) {
                const isValidType = (file.type === 'audio/mp3' || file.type === "audio/mpeg");
                const isLt20M = file.size / 1024 / 1024 < 20;

                if (!isValidType) {
                    this.$message.error('不支持该类型的文件上传。');
                    return false;
                }
                if (!isLt20M) {
                    this.$message.error('上传文件大小不能超过 20MB!');
                    return false;
                }
                return true;
            },
            handleVideoPreview(file) {
                this.videoUrlDialogVisible=true;
                console.log(file);
            },
            handleVideoRemove() {
                this.form.videoUrl='';
            },

            handleVideoSuccess(response, file, fileList) {
                console.log(file.size)
                if (response.code === 1) {
                    this.form.videoName=file.name;
                    this.form.videoUrl = response.data ;
                }
            },

            handleVideoExceed(files, fileList) {
                this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
            },

            onSubmit() {
                var that = this;
                that.form.status = '1';
                that.form.systemUser = sessionStorage.getItem('Authorization');
                delete that.form['createTime'];
                delete that.form['updateTime'];
                if (that.form.content.length > 20000) {
                    that.$message({
                        type: 'warning',
                        message: '内容不能超过20000个字符',
                    });
                    return;
                }
                var para = Object.assign({}, that.form);
                reqOtherPageEdit(para).then((res) => {
                    if (res.data.code === 1) {
                        that.$message({
                            message: '保存成功',
                            type: 'success'
                        });
                        that.$router.push({path: that.editPath});
                    } else {
                        that.$message({
                            message: '保存失败',
                            type: 'error'
                        });
                    }
                })
            },
            temporarySubmit() {
                var that = this;
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        if (that.form.content.length > 20000) {
                            that.$message({
                                type: 'warning',
                                message: '内容不能超过20000个字符',
                            });
                            return;
                        }
                        that.form.status = '0';
                        that.form.systemUser = sessionStorage.getItem('Authorization');
                        delete that.form['createTime'];
                        delete that.form['updateTime'];
                        var para = Object.assign({}, that.form);
                        reqOtherPageEdit(para).then((res) => {
                            if (res.data.code === 1) {
                                that.$message({
                                    message: '发布成功',
                                    type: 'success'
                                });
                                that.$refs.form.resetFields();
                                that.$router.push({path: that.editPath});
                            } else {
                                that.$message({
                                    message: '发布失败',
                                    type: 'error'
                                });
                            }
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            getOtherPage(id) {
                var that = this;
                let param = {
                    id: id,
                };
                reqOtherPageById(param).then((res) => {
                    this.form = Object.assign({}, res.data.data);
                    if(this.form.videoUrl){
                        this.videoUrlList.push({url:this.form.videoUrl,name:this.form.videoName})
                    }
                    that.defaultMsg = this.form.content
                })
            }
        },
        mounted() {
            var row = this.$route.query;
            this.getOtherPage(row.id);
            tinymce.init({});
        }
    }
</script>
