import axios from 'axios'
import qs from 'qs';
import {SERVER_HOST} from './../common/portConfig'
var instance = axios.create({
    headers: {
        'Authorization': ''
    }
});
instance.interceptors.request.use(
    config => {
        config.headers.Authorization = sessionStorage.getItem('Authorization');
        return config
    }, err => {
        return Promise.reject(err)
    });
// 其他页面
export const reqOtherPageListPage=params=>{return instance.get(`${SERVER_HOST}/admin/otherPage/list`,{params:params})};
export const reqOtherPageById=params=>{return instance.get(`${SERVER_HOST}/admin/otherPage/one`,{params:params})};
export const reqOtherPageEdit=params=>{return instance.post(`${SERVER_HOST}/admin/otherPage/edit`,qs.stringify(params))};
